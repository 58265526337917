import { Input } from "antd";
import React from "react";
import Error from "../Error";

const Inputcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const handleChange = (e, index, ind) => {
    let data = [...form];
    data[index].options[ind].form.value = e?.target?.value;

    setForm(data);
    getFormdata(data);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <Input
        key={index?.toString()?.concat(ind)}
        id={index?.toString()?.concat(ind)}
        name={val?.form?.name}
        placeholder={val?.form?.placeholder}
        className={val?.form?.className ? val?.form?.className : "text-regular"}
        ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
        size={val?.form?.size}
        type={val?.form?.type}
        prefix={val?.form?.prefix}
        suffix={val?.form?.suffix}
        value={val?.form?.value}
        onChange={(e) => handleChange(e, index, ind)}
        onKeyDown={handleOnKeyDown}
        autoComplete="off"
      />
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Inputcomponent;
