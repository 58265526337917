import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Expenses/Expenses/forms";
import headers from "../../../../Data/Forms/Expenses/Expenses/headers";
import Loadercomponent from "../../../../Componets/Loader";
import {
  create_expense,
  get_expense,
  update_expense,
} from "../../../../Controllers/Expenses/Expenses";

const ExpensesForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const expense = id
        ? await update_expense({ id: id, ...data?.data })
        : await create_expense(data?.data);

      if (expense?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(expense?.message);
        setButtonLoader(false);
      } else {
        toast?.error(expense?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (expense) => {
    let number = expense?.number ? expense?.number : "";
    let date = expense?.date ? expense?.date : "";
    let category = expense?.category?._id ? expense?.category?._id : null;
    let description = expense?.description ? expense?.description : "";
    let amount = expense?.amount ? expense?.amount : 0;

    let category_option = expense?.category?._id
      ? [{ value: expense?.category?._id, label: expense?.category?.name }]
      : null;

    forms[0].options[0].form.value = number;
    forms[0].options[1].form.value = date;
    forms[0].options[2].form.value = category;
    forms[0].options[2].form.options = category_option;

    forms[1].options[0].form.value = description;

    forms[2].options[0].form.value = amount;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const expense = await get_expense(data);

      if (expense?.status) {
        handleData(expense?.data);
      } else {
        toast?.error(expense?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ExpensesForm;
