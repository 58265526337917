import { Input, Select } from "antd";
import React, { createRef, useState } from "react";
import AddButton from "../../../../Componets/Buttons/add";
import { select_search } from "../../../../Controllers/Global";
import toast from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons";
import Error from "../../../../Componets/Error";

const Inventoryunittable = React.forwardRef((props, focus) => {
  const { details, setDetails } = props;

  const handleChange = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index][e?.target?.name] = e?.target?.value;

      setDetails(data);
    }
  };

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">Name</td>
            <td className="border p-4 text-black">Conversion</td>
            <td className="border p-4 text-black">Purchase price</td>
            <td className="border p-4 text-black">Price/unit</td>
            <td className="border p-4 text-black">Sale price</td>
            <td className="border p-4 text-black">Stock</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-left">{value?.name}</td>
                  <td className="border p-4">{value?.conversion}</td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="purchase_price"
                      type="number"
                      className="w-full text-regular"
                      placeholder="Purchase price"
                      value={value?.purchase_price}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="price_per_unit"
                      type="number"
                      className="w-full text-regular"
                      placeholder="Price/unit"
                      value={value?.price_per_unit}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="sale_price"
                      type="number"
                      className="w-full text-regular"
                      placeholder="Sale price"
                      value={value?.sale_price}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4">
                    <Input
                      key={index}
                      name="stock"
                      type="number"
                      className="w-full text-regular"
                      placeholder="Stock"
                      value={value?.stock}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                    />
                    <Error id={`stock${index}`} />
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default Inventoryunittable;
