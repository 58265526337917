import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Products/Units/tables";
import headers from "../../../../Data/Tables/Products/Units/headers";
import { get_all_product_units } from "../../../../Controllers/Products/Units";
import Loadercomponent from "../../../../Componets/Loader";

const UnitsTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};

    header?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};

    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const units = await get_all_product_units(params);

      if (units?.status) {
        let data = [];

        units?.data?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/products/units/edit/${value?._id}`}
                id={value?._id}
                api="delete-product-unit"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: units?.data?.currentPage,
          totalCount: units?.data?.totalCount,
          totalPages: units?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(units?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const headerReset = () => {
    let componentData = [];
    headers?.components?.map((value, index) => {
      componentData?.push({
        ...value,
        value: null,
      });
    });
    setHeader({ ...headers, components: componentData });
  };

  useEffect(() => {
    fetchData();
    headerReset();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default UnitsTable;
