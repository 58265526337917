import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import Error from "../Error";

const Passwordcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const handleChange = (e, index, ind) => {
    let data = [...form];
    data[index].options[ind].form.value = e?.target?.value;

    setForm(data);
    getFormdata(data);
  };

  const handleHideShow = (ind) => {
    let data = [...form];
    data[index].options[ind].form.show = !data[index].options[ind].form.show;

    setForm(data);
    getFormdata(data);
  };

  return (
    <React.Fragment>
      <Input
        key={index?.toString()?.concat(ind)}
        id={index?.toString()?.concat(ind)}
        name={val?.form?.name}
        placeholder={val?.form?.placeholder}
        className={val?.form?.className ? val?.form?.className : "text-regular"}
        ref={focus.current[val?.form?.name?.concat(index + "" + ind)]}
        size={val?.form?.size}
        type={val?.form?.show ? "text" : "password"}
        suffix={
          val?.form?.show ? (
            <EyeOutlined
              className="text-gray-500"
              onClick={() => handleHideShow(ind)}
            />
          ) : (
            <EyeInvisibleOutlined
              className="text-gray-500"
              onClick={() => handleHideShow(ind)}
            />
          )
        }
        value={val?.form?.value}
        onChange={(e) => handleChange(e, index, ind)}
        autoComplete="off"
      />
      <Error id={val?.form?.name?.concat(index + "" + ind)} />
    </React.Fragment>
  );
});

export default Passwordcomponent;
