import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ThermalInvoice from "./ThermalInvoice";
import Barcode from "react-barcode";
import products from "../../../../Data/products.json";
import inventory from "../../../../Data/inventory.json";
import { PrinterOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { get_invoice } from "../../../../Controllers/Sales/Invoices";
import toast from "react-hot-toast";
import InvoiceA5 from "./a5Invoice";
import { Button } from "antd";
import Header from "../../../../Componets/Header";
import A4Invoice from "./a4nvoice";

const InvoiceView = (props) => {
  let { id } = useParams();

  const invoiceRef = useRef();
  const invoiceA4Ref = useRef();
  const invoiceA5Ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleA4Print = useReactToPrint({
    content: () => invoiceA4Ref.current,
  });

  const handleA5Print = useReactToPrint({
    content: () => invoiceA5Ref.current,
  });

  const items = [
    {
      name: "LAMICTAL LIQUI-TABS 5MG",
      quantity: 1,
      Strip: 0,
      tablets: 0,
      price: 1.95,
      tax: 0.345,
      total: 2.047,
    },
    {
      name: "HAVRIX VACCINE 1440",
      quantity: 3,
      Strip: 0,
      tablets: 0,
      price: 6.95,
      tax: 1.043,
      total: 21.893,
    },
    {
      name: "ENGERIX VACCINE PAD10MCG/VIAL",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 3.95,
      tax: 0.198,
      total: 4.147,
    },
    {
      name: "ZESTRIL TABLETS 5MG",
      quantity: 0,
      strips: 3,
      tablets: 0,
      price: 0.825,
      tax: 0.124,
      total: 2.599,
    },
    {
      name: "CLARITINE TABLETS 10MG",
      quantity: 0,
      strips: 2,
      tablets: 5,
      price: 1.413,
      tax: 0.155,
      total: 3.246,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
  ];

  const total = items.reduce((acc, item) => acc + item.total, 0);

  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState();

  const handleData = (invoice) => {
    setValues(invoice);
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const invoice = await get_invoice(data);
      if (invoice?.status) {
        handleData(invoice?.data);
        console.log(invoice?.data, "invoice?.datainvoice?.datainvoice?.data");
      } else {
        toast?.error(invoice?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <div className="">
        <div className="pb-8">
          <div className="flex justify-end">
            <div className="pt-2 " onClick={handlePrint}>
              <Button size="large">
                Thermal Print <PrinterOutlined className="text-lg" />
              </Button>
            </div>
            <div className="p-2" onClick={handleA4Print}>
              <Button size="large">
                A4 Print <PrinterOutlined className="text-lg" />
              </Button>
            </div>
            <div className="p-2" onClick={handleA5Print}>
              <Button size="large">
                A5 Print <PrinterOutlined className="text-lg" />
              </Button>
            </div>
          </div>
        </div>

        <div className="hidden-on-screen">
          <ThermalInvoice
            ref={invoiceRef}
            values={values}
            items={items}
            total={total}
          />
        </div>

        <div>
          <A4Invoice
            ref={invoiceA4Ref}
            values={values}
            items={items}
            total={total}
          />
        </div>

        <div className="hidden-on-screen">
          <InvoiceA5
            ref={invoiceA5Ref}
            values={values}
            items={items}
            total={total}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
