import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_product_category = async (category) => {
  try {
    const data = {
      name: category?.name,
      status: category?.status,
      branch: category?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-product-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_product_category = async (category) => {
  try {
    const data = {
      id: category?.id,
      name: category?.name,
      status: category?.status,
      branch: category?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-product-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_category = async (category) => {
  try {
    const data = {
      id: category?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-category`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_categories = async (category) => {
  try {
    const data = {
      search: category?.search,
      status: category?.status,
      page: category?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-categories`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_category_log = async (category) => {
  try {
    const data = {
      search: category?.search,
      sort: category?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-category-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_category_logs = async (category) => {
  try {
    const data = {
      search: category?.search,
      sort: category?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-category-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_product_category,
  update_product_category,
  get_product_category,
  get_all_product_categories,
  get_product_category_log,
  get_all_product_category_logs,
};
