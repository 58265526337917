import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_purchases } from "../../../../Controllers/Purchases/Orders";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Purchases/Orders/tables";
import headers from "../../../../Data/Tables/Purchases/Orders/headers";
import Loadercomponent from "../../../../Componets/Loader";
import moment from "moment";

const PurchasesOrderTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};
    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      supplier: headerdata?.supplier,
      contractor: headerdata?.contractor,
      date: headerdata?.date,
      due_date: headerdata?.due_date,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};
    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      unit: headerdata?.unit,
      category: headerdata?.category,
      brand: headerdata?.brand,
      type: headerdata?.type,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        supplier: tabledata?.supplier,
        contractor: tabledata?.contractor,
        status: tabledata?.status,
        date: tabledata?.date,
        due_date: tabledata?.due_date,
        page: tabledata?.currentPage,
      };

      const purchases = await get_all_purchases(data);
      if (purchases?.status) {
        let data = [];

        purchases?.data?.data?.map((value, index) => {
          data?.push({
            path: `/app/purchases/orders/view/${value?._id}`,
            Number: "#PO-" + value?.number,
            Supplier: value?.supplier?.name,
            Date: moment(value?.date).format("MMMM Do YYYY"),
            Due: moment(value?.due_date).format("MMMM Do YYYY"),
            Amount: value?.total?.toFixed(3),
            Delivery:
              value?.delivery_status == 2 ? (
                <span className="bg-green-100 text-green-600 text-sm px-2 py-1 rounded-lg">
                  Delivered
                </span>
              ) : value?.delivery_status == 1 ? (
                <span className="bg-yellow-100 text-yellow-600 text-sm px-2 py-1 rounded-lg">
                  Partial
                </span>
              ) : (
                <span className="bg-red-100 text-red-600 text-sm px-2 py-1 rounded-lg">
                  Pending
                </span>
              ),
            Payment:
              value?.payment_status == 2 ? (
                <span className="bg-green-100 text-green-600 text-sm px-2 py-1 rounded-lg">
                  Paid
                </span>
              ) : value?.payment_status == 1 ? (
                <span className="bg-yellow-100 text-yellow-600 text-sm px-2 py-1 rounded-lg">
                  Partial
                </span>
              ) : (
                <span className="bg-red-100 text-red-600 text-sm px-2 py-1 rounded-lg">
                  Unpaid
                </span>
              ),
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/purchases/orders/edit/${value?._id}`}
                id={value?._id}
                api="delete-purchase-order"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: purchases?.data?.currentPage,
          totalCount: purchases?.data?.totalCount,
          totalPages: purchases?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(purchases?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default PurchasesOrderTable;
