import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const get_user_auth = async (project_job) => {
  try {
    const data = {};

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-user-auth`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { get_user_auth };
