const headers = {
  name: "Expenses",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      value: null,
      options: [
        { value: "1", label: "Amount (Low)" },
        { value: "2", label: "Amount (High)" },
      ],
    },
    {
      type: "filter",
      name: "category",
      placeholder: "Category",
      allowClear: true,
      showSearch: true,
      filterOption: false,
      api: "get-all-expense-categories",
      value: null,
      options: null,
    },
    {
      type: "range",
      name: "date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "add",
      path: "/app/expenses/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Expenses",
        path: "",
      },
      {
        name: "Categories",
        path: "/app/expenses/categories",
      },
    ],
    total: [
      { value: "0", label: "Total" },
      { value: "0", label: "Active" },
      { value: "0", label: "Inactive" },
    ],
  },
};

export default headers;
