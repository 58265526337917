import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Customers/Customers/tables";
import headers from "../../../../Data/Tables/Customers/Customers/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_customers } from "../../../../Controllers/Customers";

const CustomersTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};

    header?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};

    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const customers = await get_all_customers(params);

      if (customers?.status) {
        let data = [];

        customers?.data?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Email: value?.email,
            Phone: value?.phone,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/customers/edit/${value?._id}`}
                id={value?._id}
                api="delete-customer"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: customers?.data?.currentPage,
          totalCount: customers?.data?.totalCount,
          totalPages: customers?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(customers?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const headerReset = () => {
    let componentData = [];
    headers?.components?.map((value, index) => {
      componentData?.push({
        ...value,
        value: null,
      });
    });
    setHeader({ ...headers, components: componentData });
  };

  useEffect(() => {
    fetchData();
    headerReset();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default CustomersTable;
