import React from "react";

const ValueTag = (props) => {
  const { type, inventory, stock } = props;

  return (
    <div>
      {type ? (
        <div>
          <span>{inventory}</span>
          <span
            className={
              inventory == 0
                ? "ml-2 bg-red-200 text-red-800 text-xs text-medium rounded-lg py-1 px-2"
                : inventory <= stock
                ? "ml-2 bg-orange-200 text-orange-800 text-xs text-medium rounded-lg py-1 px-5"
                : "ml-2 bg-green-200 text-green-800 text-xs text-medium rounded-lg py-1 px-2"
            }
          >
            {inventory == 0
              ? "Out Of Stock"
              : inventory <= stock
              ? "Low"
              : "Stocked"}
          </span>
        </div>
      ) : (
        <div>
          <span
            className={
              "ml-2 bg-cyan-200 text-cyan-800 text-xs text-medium rounded-lg py-1 px-2"
            }
          >
            Nonstockable
          </span>
        </div>
      )}
    </div>
  );
};

export default ValueTag;
