import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Customers/Customers/forms";
import headers from "../../../../Data/Forms/Customers/Customers/headers";
import Loadercomponent from "../../../../Componets/Loader";
import {
  create_customer,
  get_customer,
  update_customer,
} from "../../../../Controllers/Customers";

const CustomersForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const customers = id
        ? await update_customer({ id: id, ...data?.data })
        : await create_customer(data?.data);

      if (customers?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(customers?.message);
        setButtonLoader(false);
      } else {
        toast?.error(customers?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (customers) => {
    let name = customers?.name ? customers?.name : "";
    let email = customers?.email ? customers?.email : "";
    let phone = customers?.phone ? customers?.phone : "";
    let tax = customers?.tax ? customers?.tax : "";
    let area = customers?.area ? customers?.area : "";
    let city = customers?.city ? customers?.city : "";
    let state = customers?.state ? customers?.state : "";
    let country = customers?.country ? customers?.country : "";
    let status = customers?.status ? customers?.status : 0;

    forms[0].options[0].form.value = name;

    forms[1].options[0].form.value = email;
    forms[1].options[1].form.value = phone;

    forms[2].options[0].form.value = tax;

    forms[3].options[0].form.value = area;
    forms[3].options[1].form.value = city;
    forms[3].options[2].form.value = state;
    forms[3].options[3].form.value = country;

    forms[3].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const customers = await get_customer(data);

      if (customers?.status) {
        handleData(customers?.data);
      } else {
        toast?.error(customers?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [buttonLoader]);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default CustomersForm;
