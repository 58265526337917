import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Inventory/Request/forms";
import headers from "../../../../Data/Forms/Inventory/Request/headers";
import branches from "../../../../Data/branch.json";
import { Checkbox } from "antd";
import products from "../../../../Data/products.json";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import RequestTable from "../../../../Data/Forms/Inventory/Request/table";
import toast from "react-hot-toast";
import {
  create_purchase,
  get_purchase,
  update_purchase,
} from "../../../../Controllers/Purchases/Orders";
import {
  create_request,
  get_request,
  update_request,
} from "../../../../Controllers/Inventory/Request";

const InventoryRequestForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const focus = useRef([]);

  const [details, setDetails] = useState([
    {
      id: "",
      description: null,
      name: "",
      unit: "",
      unit_name: "",
      unit_details_options: null,
      conversion: 0,
      quantity: "",
      purchase: "",
      purchase_price: "",
      purchase_options: null,
      delivered: "",
      tax: 0,
      free: "",
      barcode: "",
      price_per_unit: "",
      sale_price: "",
      expiry_date: "",
      tax_amount: "0.000",
      total: 0,
    },
  ]);

  const detailsvalidation = (tabledata) => {
    let params = {};

    if (details?.length > 0) {
      let total = details?.length;
      let count = 0;

      let validated = [];
      details?.map((v, i) => {
        if (count == i) {
          if (!v?.description) {
            document.getElementById(
              `description${i}`
            ).textContent = `Description is required`;
            focus.current[`description${i}`].current.focus();
          } else if (!v?.quantity) {
            document.getElementById(
              `quantity${i}`
            ).textContent = `Quantity is required`;
            focus.current[`quantity${i}`].current.focus();
          } else {
            document.getElementById(`description${i}`).textContent = ``;
            document.getElementById(`quantity${i}`).textContent = ``;

            if (v?.unit_details_options?.length > 0) {
              let total_unit = v?.unit_details_options?.length;
              let count_unit = 0;

              v?.unit_details_options?.map((value, index) => {
                if (count_unit == index) {
                  count_unit++;
                }
              });

              if (count_unit == total_unit) {
                validated?.push({ ...v, options: [], purchase_options: [] });
                count++;
              }
            } else {
              validated?.push({ ...v, options: [], purchase_options: [] });
              count++;
            }
          }
        }
      });

      if (count == total) {
        params = {
          status: true,
          data: {
            ...tabledata,
            details: validated,
          },
        };

        return params;
      } else {
        params = {
          status: false,
          data: null,
        };

        return params;
      }
    } else {
      toast?.error("Details not found");
    }
  };

  const formdata = async (data) => {
    if (data?.status) {
      const tabledetailsvalidation = detailsvalidation(data?.data);

      if (tabledetailsvalidation?.status) {
        setButtonLoader(true);

        const purchase = id
          ? await update_request({ ...tabledetailsvalidation?.data, id: id })
          : await create_request(tabledetailsvalidation?.data);

        if (purchase?.status) {
          if (id) {
            navigate(-1);
          }
          toast?.success(purchase?.message);
          setButtonLoader(false);
        } else {
          toast?.error(purchase?.message);
          setButtonLoader(false);
        }
      }
    }
  };

  const handleData = (purchase) => {
    let supplier = purchase?.supplier?._id ? purchase?.supplier?._id : null;
    let number = purchase?.number ? purchase?.number : "";
    let date = purchase?.date ? purchase?.date : "";
    let due_date = purchase?.due_date ? purchase?.due_date : "";
    let status = purchase?.status ? purchase?.status : 0;

    let supplier_option = purchase?.supplier?._id
      ? [
          {
            value: purchase?.supplier?._id,
            label: purchase?.supplier?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = date;
    values[0].options[1].form.value = supplier;
    values[0].options[1].form.options = supplier_option;
    values[0].options[2].form.value = status;

    let data = [];

    purchase?.details?.map((value, index) => {
      data?.push({
        id: value?._id,
        description: value?.description?._id,
        name: value?.description?.name,
        unit: value?.unit,
        unit_name: value?.unit_name,
        unit_details_options: value?.unit_details_options,
        conversion: value?.conversion,
        quantity: value?.quantity,
      });
    });

    setValues([...values]);
    setDetails([...data]);
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const purchase = await get_request(data);
      if (purchase?.status) {
        handleData(purchase?.data);
      } else {
        toast?.error(purchase?.message);
      }
    } else {
      values[0].options[0].form.value = new Date();

      setValues([...values]);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms
        form={values}
        setForm={setValues}
        formdata={formdata}
        buttonLoader={buttonLoader}
      >
        <Bodycomponent>
          <Titlecomponent index={1} title={"Items"} />
          <Formdata>
            <Headercomponent index={0} name={"Details"} required={true} />
            <RequestTable
              details={details}
              setDetails={setDetails}
              ref={focus}
            />
          </Formdata>
        </Bodycomponent>
      </Forms>
    </Header>
  );
};

export default InventoryRequestForm;
