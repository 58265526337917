import React from "react";
import DefaultImage from "../../Assets/Images/defaultimage.png";
import URL from "../../Main/url";

const TableImage = (props) => {
  let image = props?.image ? `${URL}/uploads${props?.image}` : "";
  let name = props?.name;

  return (
    <div className="flex">
      <div>
        <img
          className="w-[60px] h-[60px] rounded-md mr-2"
          src={image ? image : DefaultImage}
          alt={"Image"}
        />
      </div>
      <div className="my-auto">{name}</div>
    </div>
  );
};

export default TableImage;
