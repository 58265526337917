import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_request = async (request) => {
  try {
    const data = {
      supplier: request?.supplier,
      number: request?.number,
      date: request?.date,
      status: request?.status,
      details: request?.details,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-request`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_request = async (request) => {
  try {
    const data = {
      id: request?.id,
      supplier: request?.supplier,
      number: request?.number,
      date: request?.date,
      status: request?.status,
      details: request?.details,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-request`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_request = async (request) => {
  try {
    const data = {
      id: request?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/delete-request`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_request = async (request) => {
  try {
    const data = {
      id: request?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-request`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_request_inventories = async (request) => {
  try {
    const data = {
      id: request?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-request-inventories`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_requests = async (request) => {
  try {
    const data = {
      search: request?.search,
      sort: request?.sort,
      branch: request?.branch,
      contractor: request?.contractor,
      date: request?.date,
      due_date: request?.due_date,
      page: request?.page,
      status: request?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-requests`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_requested = async (request) => {
  try {
    const data = {
      search: request?.search,
      sort: request?.sort,
      branch: request?.branch,
      contractor: request?.contractor,
      date: request?.date,
      due_date: request?.due_date,
      page: request?.page,
      status: request?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-requested`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_request,
  update_request,
  delete_request,
  get_request,
  get_all_requests,
  get_all_requested,
};
