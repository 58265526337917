import React, { createRef, useEffect, useRef } from "react";
import { Button, Modal } from "antd";

const Modalcomponent = (props) => {
  const {
    open,
    setOpen,
    modalData,
    setModalData,
    selcectedModalData,
    setSelcectedModalData,
    handleModal,
  } = props;

  const focus = useRef([]);

  const handleCancel = () => {
    setOpen(false);
    setModalData();
    setSelcectedModalData();
  };

  const handleSubmit = (index) => {
    const unit = modalData[index];
    const id = unit?._id;

    handleModal(id);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        focus.current[0].current.focus();
      }, 100);
    }
  }, [open]);

  return (
    <>
      <Modal closable={false} open={open} title="" footer={null}>
        <div className="flex">
          {modalData?.map((value, index) => {
            focus.current[index] = createRef();
            return (
              <button
                key={index}
                ref={focus.current[index]}
                className="p-4 text-medium rounded-lg mr-3 w-full focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500 border"
                onClick={() => handleSubmit(index)}
              >
                {value?.name?._id ? value?.name?.name : value?.name}
              </button>
            );
          })}
        </div>
        <div className="flex justify-end pt-4 pr-3">
          <Button
            className="text-red-500 border-2 border-red-500"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Modalcomponent;
