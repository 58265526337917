const headers = {
  name: "Inventory request",
  navigate: {
    path: [
      {
        name: "Request",
        path: "/app/inventory/request",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
