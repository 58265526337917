import { Spin } from "antd";
import React from "react";

const Loadercomponent = (props) => {
  return (
    <React.Fragment>
      {props?.loader ? (
        <div className="flex justify-center items-center h-[60vh]">
          <Spin tip="Loading" size="large" />
        </div>
      ) : (
        props?.children
      )}
    </React.Fragment>
  );
};

export default Loadercomponent;
