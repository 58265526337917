import React from "react";
import headers from "../../Data/Tables/Reports/Reports/headers";
import Header from "../../Componets/Header";
import Menus from "../../Componets/Menus";
import ProductIcon from "../../Assets/Svg/products";
import InventoryIcon from "../../Assets/Svg/inventory";
import BillingIcon from "../../Assets/Svg/billing";
import SalesIcon from "../../Assets/Svg/sales";
import PurchaseIcon from "../../Assets/Svg/purchase";
import ExpenseIcon from "../../Assets/Svg/expense";
import CustomerIcon from "../../Assets/Svg/customer";
import SupplierIcon from "../../Assets/Svg/supplier";

const Reports = () => {
  let header = headers;

  const formdata = async (data) => {
    console.log(data);
  };

  const menu = [
    {
      icon: <ProductIcon />,
      name: "Products",
      description: "View products report",
      url: "/app/reports/products",
    },
    {
      icon: <ProductIcon />,
      name: "Products low stock",
      description: "View products low stock report",
      url: "/app/reports/products/low/stock",
    },
    {
      icon: <ProductIcon />,
      name: "Products out of stock",
      description: "View products out of stock report",
      url: "/app/reports/products/out/of/stock",
    },

    // {
    //   icon: <ProductIcon />,
    //   name: "Product sold",
    //   description: "View product sale report",
    //   url: "/app/reports/products/sold",
    // },
    // {
    //   icon: <ProductIcon />,
    //   name: "Product purchase",
    //   description: "View product purchase report",
    //   url: "/app/reports/products/purchase",
    // },

    {
      icon: <InventoryIcon />,
      name: "Inventory",
      description: "View inventory report",
      url: "/app/reports/inventory",
    },
    {
      icon: <InventoryIcon />,
      name: "Inventory near expiry",
      description: "View inventory near expiry report",
      url: "/app/reports/inventory/near/expiry",
    },
    {
      icon: <InventoryIcon />,
      name: "Inventory expired",
      description: "View inventory expired report",
      url: "/app/reports/inventory/expired",
    },

    {
      icon: <SalesIcon />,
      name: "Sales",
      description: "View sales report",
      url: "/app/reports/sales",
    },
    {
      icon: <PurchaseIcon />,
      name: "Purchases",
      description: "View purchases report",
      url: "/app/reports/purcases",
    },
    {
      icon: <ExpenseIcon />,
      name: "Expenses",
      description: "View expenses report",
      url: "/app/reports/expenses",
    },
    // {
    //   icon: <CustomerIcon />,
    //   name: "Customer",
    //   description: "View customer report",
    //   url: "/app/reports/customers",
    // },
    // {
    //   icon: <SupplierIcon />,
    //   name: "Suppliers",
    //   description: "View suppliers report",
    //   url: "/app/reports/suppliers",
    // },
  ];

  return (
    <Header header={header} formdata={formdata}>
      <div className="mt-4 grid grid-cols-4 gap-4">
        {menu?.map((value, index) => {
          return (
            <Menus
              icon={value?.icon}
              name={value?.name}
              description={value?.description}
              url={value?.url}
            />
          );
        })}
      </div>
    </Header>
  );
};

export default Reports;
