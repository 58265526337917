import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";

const create_customer = async (customer) => {
  try {
    let data = {
      name: customer?.name,
      email: customer?.email,
      phone: customer?.phone,
      tax: customer?.tax,
      area: customer?.area,
      city: customer?.city,
      state: customer?.state,
      country: customer?.country,
      status: customer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-customer`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_customer = async (customer) => {
  try {
    let data = {
      id: customer?.id,
      name: customer?.name,
      email: customer?.email,
      phone: customer?.phone,
      tax: customer?.tax,
      area: customer?.area,
      city: customer?.city,
      state: customer?.state,
      country: customer?.country,
      status: customer?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-customer`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const delete_customer = async (customer) => {
  try {
    let data = {
      id: customer?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-customer`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_customer = async (customer) => {
  try {
    let data = {
      id: customer?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-customer`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_customers = async (customer) => {
  try {
    const data = {
      search: customer?.search,
      sort: customer?.sort,
      status: customer?.status,
      page: customer?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-customers`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_customer_log = async (customer) => {
  try {
    const data = {
      id: customer?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-customer-log`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_customers_log = async (customer) => {
  try {
    const data = {
      customer: customer?.customer,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-customers-log`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_customer,
  update_customer,
  delete_customer,
  get_customer,
  get_all_customers,
  get_customer_log,
  get_all_customers_log,
};
