import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_role = async (role) => {
  try {
    const data = {
      name: role?.name,
      status: role?.status,
      details: role?.details,
      branch: role?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-role`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_role = async (role) => {
  try {
    const data = {
      id: role?.id,
      name: role?.name,
      status: role?.status,
      details: role?.details,
      branch: role?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-role`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_role = async (role) => {
  try {
    const data = {
      id: role?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-role`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_roles = async (role) => {
  try {
    const data = {
      search: role?.search,
      status: role?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-all-roles`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_role_log = async (role) => {
  try {
    const data = {
      search: role?.search,
      sort: role?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-role-log`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_role_logs = async (role) => {
  try {
    const data = {
      search: role?.search,
      sort: role?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-role-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_role,
  update_role,
  get_role,
  get_all_roles,
  get_role_log,
  get_all_role_logs,
};
