import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_branch = async (branch) => {
  try {
    let data = new FormData();
    data.append("image", branch?.image[0]);
    data.append("name", branch?.name);
    data.append("email", branch?.email);
    data.append("phone", branch?.phone);
    data.append("tax", branch?.tax);
    data.append("area", branch?.area);
    data.append("city", branch?.city);
    data.append("state", branch?.state);
    data.append("country", branch?.country);
    data.append("status", branch?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-branch`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_branch = async (branch) => {
  try {
    let data = new FormData();
    data.append("id", branch?.id);
    data.append("image", branch?.image[0]);
    data.append("name", branch?.name);
    data.append("email", branch?.email);
    data.append("phone", branch?.phone);
    data.append("tax", branch?.tax);
    data.append("area", branch?.area);
    data.append("city", branch?.city);
    data.append("state", branch?.state);
    data.append("country", branch?.country);
    data.append("status", branch?.status);

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-branch`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_branch = async (branch) => {
  try {
    const data = {
      id: branch?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-branch`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_branches = async (branch) => {
  try {
    const data = {
      search: branch?.search,
      sort: branch?.sort,
      unit: branch?.unit,
      category: branch?.category,
      brand: branch?.brand,
      type: branch?.type,
      status: branch?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-branches`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export { create_branch, update_branch, get_branch, get_all_branches };
