import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Products/Brands/forms";
import headers from "../../../../Data/Forms/Products/Brands/headers";
import {
  create_product_brand,
  get_product_brand,
  update_product_brand,
} from "../../../../Controllers/Products/Brands";
import Loadercomponent from "../../../../Componets/Loader";

const BrandsForm = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const brand = id
        ? await update_product_brand({ id: id, ...data?.data })
        : await create_product_brand(data?.data);

      if (brand?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(brand?.message);
        setButtonLoader(false);
      } else {
        toast?.error(brand?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (brand) => {
    let name = brand?.name ? brand?.name : "";
    let status = brand?.status ? brand?.status : 0;

    forms[0].options[0].form.value = name;
    forms[1].options[0].form.value = status;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const brand = await get_product_brand(data);

      if (brand?.status) {
        handleData(brand?.data);
      } else {
        toast?.error(brand?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default BrandsForm;
