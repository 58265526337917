const headers = {
  name: "Inventory received",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      value: null,
      options: [
        { value: "1", label: "Name (A-Z)" },
        { value: "2", label: "Name (Z-A)" },
        { value: "3", label: "Price (Low)" },
        { value: "4", label: "Price (High)" },
        { value: "5", label: "Balance (Low)" },
        { value: "6", label: "Balance (High)" },
      ],
    },
    {
      type: "filter",
      name: "payment",
      placeholder: "Payment",
      value: null,
      options: [
        { value: 0, label: "Paid" },
        { value: 1, label: "Partial" },
        { value: 2, label: "Unpaid" },
      ],
    },
    {
      type: "filter",
      name: "status",
      placeholder: "Status",
      value: null,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },
    {
      type: "range",
      name: "date",
      value: {
        start: "",
        end: "",
      },
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Batch",
        path: "/app/inventory",
      },
      {
        name: "Transfer",
        path: "/app/inventory/transfer",
      },
      {
        name: "Received",
        path: "",
      },
      {
        name: "Request",
        path: "/app/inventory/request",
      },
      {
        name: "Requested",
        path: "/app/inventory/requested",
      },
    ],
    total: [
      { value: "1", label: "Total" },
      { value: "1", label: "Active" },
      { value: "1", label: "Inactive" },
      { value: "1", label: "Paid" },
      { value: "1", label: "Partial" },
      { value: "1", label: "Unpaid" },
    ],
  },
};

export default headers;
