import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_inventory = async (inventory) => {
  try {
    let data = {
      number: inventory?.number,
      purchase: inventory?.purchase,
      product: inventory?.product,
      barcode: inventory?.barcode,
      supplier: inventory?.supplier,
      purchase_price: inventory?.purchase_price,
      price_per_unit: inventory?.price_per_unit,
      sale_price: inventory?.sale_price,
      tax: inventory?.tax,
      stock: inventory?.stock,
      manufacture_date: inventory?.manufacture_date,
      expiry_date: inventory?.expiry_date,
      details: inventory?.details,
      status: inventory?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-inventory`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_inventory = async (inventory) => {
  try {
    let data = {
      id: inventory?.id,
      number: inventory?.number,
      purchase: inventory?.purchase,
      product: inventory?.product,
      barcode: inventory?.barcode,
      supplier: inventory?.supplier,
      purchase_price: inventory?.purchase_price,
      price_per_unit: inventory?.price_per_unit,
      sale_price: inventory?.sale_price,
      tax: inventory?.tax,
      stock: inventory?.stock,
      manufacture_date: inventory?.manufacture_date,
      expiry_date: inventory?.expiry_date,
      details: inventory?.details,
      status: inventory?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-inventory`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_inventory = async (inventory) => {
  try {
    const data = {
      id: inventory?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-inventory`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_inventories = async (inventory) => {
  try {
    const data = {
      search: inventory?.search,
      sort: inventory?.sort,
      product: inventory?.product,
      purchase: inventory?.purchase,
      supplier: inventory?.supplier,
      date: inventory?.date,
      status: inventory?.status,
      page: inventory?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-inventories`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_inventory_barcode = async (inventory) => {
  try {
    const data = {
      barcode: inventory?.barcode,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-inventory-barcode`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_inventory,
  update_inventory,
  get_inventory,
  get_all_inventories,
  get_inventory_barcode,
};
