import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import tables from "../../../../Data/Tables/Settings/Branches/tables";
import headers from "../../../../Data/Tables/Settings/Branches/headers";
import branches from "../../../../Data/branch.json";
import toast from "react-hot-toast";
import ActionButton from "../../../../Componets/Buttons/action";
import Status from "../../../../Componets/Status";
import TableImage from "../../../../Componets/Image";
import Loadercomponent from "../../../../Componets/Loader";
import { get_all_branches } from "../../../../Controllers/Settings/Branches";

const BranchesTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
      };

      const branches = await get_all_branches(data);
      if (branches?.status) {
        let data = [];

        branches?.data?.data?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Phone: value?.phone,
            Email: value?.email,
            Country: value?.country,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/settings/branches/edit/${value?._id}`}
                id={value?._id}
                api="delete-branch"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(branches?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default BranchesTable;
