import React from "react";
import axios from "axios";
import APIURL from "../../Main/apiurl";
import URL from "../../Main/url";

const select_search = async (select) => {
  try {
    const data = {
      search: select?.search,
      status: select?.status,
      ...select,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/${select?.api}`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const select_id = async (select) => {
  try {
    const data = {
      id: select?.id,
      status: select?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/${select?.api}`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_table = async (table) => {
  try {
    const api = table?.api;

    const data = {
      id: table?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/${api}`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const convert_to_file = async (image) => {
  console.log(image, "imageimage");
  if (image) {
    const fileName = image.split("/").pop();

    const modifiedFileName = fileName.replace(/^\d+_/, "");

    const response = await fetch(`${URL}/uploads${image}`);

    const blob = await response.blob();

    const mimeType = blob.type;

    const file = new File([blob], modifiedFileName, { type: mimeType });

    file.path = `${URL}/uploads${image}`;
    file.preview = window.URL.createObjectURL(file);

    return [file];
  } else {
    return "";
  }
};

export { select_search, select_id, delete_table, convert_to_file };
