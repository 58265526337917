import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_purchase = async (purchase) => {
  try {
    const data = {
      supplier: purchase?.supplier,
      project: purchase?.project,
      number: purchase?.number,
      invoice: purchase?.invoice,
      date: purchase?.date,
      due_date: purchase?.due_date,
      status: purchase?.status,
      details: purchase?.details,
      subtotal: purchase?.subtotal,
      taxamount: purchase?.taxamount,
      discount: purchase?.discount,
      delivery: purchase?.delivery,
      delivery_status: purchase?.delivery_status,
      delivery_date: purchase?.delivery_date,
      payment_status: purchase?.payment_status,
      payment_types: JSON?.stringify(purchase?.payment_types),
      payments: JSON?.stringify(purchase?.payments),
      total: purchase?.total,
    };

    console.log(data, "datadata");

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-purchase-order`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_purchase = async (purchase) => {
  try {
    const data = {
      id: purchase?.id,
      supplier: purchase?.supplier,
      project: purchase?.project,
      number: purchase?.number,
      invoice: purchase?.invoice,
      date: purchase?.date,
      due_date: purchase?.due_date,
      status: purchase?.status,
      details: purchase?.details,
      subtotal: purchase?.subtotal,
      taxamount: purchase?.taxamount,
      discount: purchase?.discount,
      delivery: purchase?.delivery,
      delivery_status: purchase?.delivery_status,
      delivery_date: purchase?.delivery_date,
      payment_status: purchase?.payment_status,
      payment_types: JSON?.stringify(purchase?.payment_types),
      payments: JSON?.stringify(purchase?.payments),
      total: purchase?.total,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-purchase-order`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_purchase = async (purchase) => {
  try {
    const data = {
      id: purchase?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-purchase-order`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchase = async (purchase) => {
  try {
    const data = {
      id: purchase?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-purchase-order`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchase_order_inventories = async (purchase) => {
  try {
    const data = {
      id: purchase?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-purchase-order-inventories`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchases = async (purchase) => {
  try {
    const data = {
      search: purchase?.search,
      sort: purchase?.sort,
      supplier: purchase?.supplier,
      contractor: purchase?.contractor,
      date: purchase?.date,
      due_date: purchase?.due_date,
      page: purchase?.page,
      status: purchase?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchase-orders`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchases_details = async (purchase) => {
  try {
    const data = {
      search: purchase?.search,
      sort: purchase?.sort,
      supplier: purchase?.supplier,
      contractor: purchase?.contractor,
      date: purchase?.date,
      due_date: purchase?.due_date,
      status: purchase?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchases-details`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchase_log = async (purchase) => {
  try {
    const data = {
      search: purchase?.search,
      sort: purchase?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchase-order-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_purchase_logs = async (purchase) => {
  try {
    const data = {
      search: purchase?.search,
      sort: purchase?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-purchase-order-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_purchase,
  update_purchase,
  delete_purchase,
  get_purchase,
  get_purchase_order_inventories,
  get_all_purchases,
  get_all_purchases_details,
  get_purchase_log,
  get_all_purchase_logs,
};
