const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Number",
        type: "input",
        form: {
          type: "text",
          name: "number",
          placeholder: "Expense number",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "date",
          placeholder: "Expense date",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Category",
        type: "selectandadd",
        path: "/app/expenses/categories/add",
        form: {
          name: "category",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Expense category",
          api: "get-all-expense-categories",
          value: null,
          options: [],
          required: true,
        },
      },
    ],
  },
  {
    name: "Description",
    options: [
      {
        name: "Description",
        type: "input",
        form: {
          type: "text",
          name: "description",
          placeholder: "Expense description",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Amount",
    options: [
      {
        name: "Amount",
        type: "input",
        form: {
          type: "number",
          name: "amount",
          placeholder: "Expense amount",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
];

export default forms;
