import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const get_dashboard = async (dashboard) => {
  try {
    const data = {
      date: dashboard?.date,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-dashboard`, data, config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_products_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      unit: dashboard?.unit,
      category: dashboard?.category,
      brand: dashboard?.brand,
      type: dashboard?.type,
      status: dashboard?.status,
      branch: dashboard?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-products-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_products_low_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      unit: dashboard?.unit,
      category: dashboard?.category,
      brand: dashboard?.brand,
      type: dashboard?.type,
      status: dashboard?.status,
      branch: dashboard?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-products-low-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_products_out_of_stock_report = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      unit: dashboard?.unit,
      category: dashboard?.category,
      brand: dashboard?.brand,
      type: dashboard?.type,
      status: dashboard?.status,
      branch: dashboard?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-products-out-of-stock-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_inventories_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      product: dashboard?.product,
      supplier: dashboard?.supplier,
      purchase: dashboard?.purchase,
      branch: dashboard?.branch,
      date: dashboard?.date,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-inventories-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_inventories_near_expiry_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      unit: dashboard?.unit,
      category: dashboard?.category,
      brand: dashboard?.brand,
      type: dashboard?.type,
      status: dashboard?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-inventories-near-expiry-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_inventories_expired_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      unit: dashboard?.unit,
      category: dashboard?.category,
      brand: dashboard?.brand,
      type: dashboard?.type,
      status: dashboard?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-inventories-expired-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_sales_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      customer: dashboard?.customer,
      branch: dashboard?.branch,
      status: dashboard?.status,
      date: dashboard?.date,
      due_date: dashboard?.due_date,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-sales-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_purchase_reports = async (dashboard) => {
  try {
    const data = {
      search: dashboard?.search,
      sort: dashboard?.sort,
      supplier: dashboard?.supplier,
      branch: dashboard?.branch,
      status: dashboard?.status,
      date: dashboard?.date,
      due_date: dashboard?.due_date,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-purchase-reports`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  get_dashboard,
  get_all_products_reports,
  get_all_products_low_reports,
  get_all_products_out_of_stock_report,
  get_all_inventories_reports,
  get_all_inventories_near_expiry_reports,
  get_all_inventories_expired_reports,
  get_sales_reports,
  get_purchase_reports,
};
