import taxes from "../../../taxes.json";

const forms = [
  {
    name: "Product",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Product name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Barcode",
        type: "input",
        form: {
          type: "text",
          name: "barcode",
          placeholder: "Product barcode",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Categorization",
    options: [
      {
        name: "Unit",
        type: "selectandadd",
        path: "/app/products/units/add",
        form: {
          name: "unit",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Product Unit",
          api: "get-all-product-units",
          options: null,
          value: null,
          required: false,
        },
      },
      {
        name: "Category",
        type: "selectandadd",
        path: "/app/products/categories/add",
        form: {
          name: "category",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Product Category",
          api: "get-all-product-categories",
          options: null,
          value: null,
          required: false,
        },
      },
      {
        name: "Brand",
        type: "selectandadd",
        path: "/app/products/brands/add",
        form: {
          name: "brand",
          allowClear: true,
          showSearch: true,
          filterOption: false,
          placeholder: "Product Brand",
          api: "get-all-product-brands",
          options: null,
          value: null,
          required: false,
        },
      },
    ],
  },
  {
    name: "Notification",
    options: [
      {
        name: "Stock",
        type: "input",
        form: {
          type: "number",
          name: "stock",
          placeholder: "Product Stock Notification",
          value: "",
          required: false,
        },
      },
      {
        name: "Expiry",
        type: "input",
        form: {
          type: "number",
          name: "expiry",
          placeholder: "Product Expiry Notification",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Taxation",
    options: [
      {
        name: "Tax [VAT]",
        type: "select",
        form: {
          name: "tax",
          allowClear: false,
          placeholder: "Tax",
          value: 0,
          options: taxes?.map((value, index) => ({
            value: value?.value,
            label: value?.label,
          })),
          required: false,
        },
      },
    ],
  },
  {
    name: "Stock",
    options: [
      {
        name: "Type",
        type: "select",
        show: { check: true },
        form: {
          name: "type",
          allowClear: false,
          placeholder: "Stockable",
          value: 1,
          options: [
            { value: 1, label: "Stockable" },
            { value: 0, label: "Non-stockable" },
          ],
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
