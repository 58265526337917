import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_products } from "../../../../Controllers/Products/Products";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Products/Products/tables";
import headers from "../../../../Data/Tables/Products/Products/headers";
import Loadercomponent from "../../../../Componets/Loader";
import ValueTag from "../../../../Componets/Valuetag";
import { useLocation } from "react-router-dom";

const ProductsTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};

    header?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      unit: headerdata?.unit,
      category: headerdata?.category,
      brand: headerdata?.brand,
      type: headerdata?.type,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};
    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      unit: headerdata?.unit,
      category: headerdata?.category,
      brand: headerdata?.brand,
      type: headerdata?.type,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      // let headerdata = [];
      // header?.components?.map((v, i) => {
      //   headerdata?.push({
      //     ...v,
      //     value: data[v?.name],
      //   });
      // });
      // setHeader({ ...header, components: headerdata });

      const products = await get_all_products(data);
      if (products?.status) {
        let data = [];

        products?.data?.data?.map((value, index) => {
          data?.push({
            Name: <TableImage image={value?.image} name={value?.name} />,
            Unit: value?.unit?.name,
            Category: value?.category?.name,
            Brand: value?.brand?.name,
            Stock: (
              <ValueTag
                type={value?.type}
                stock={value?.stock}
                inventory={
                  Number.isInteger(value?.total_inventory)
                    ? parseFloat?.(value?.total_inventory || 0)
                    : parseFloat?.(value?.total_inventory || 0)?.toFixed(3)
                }
              />
            ),
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/products/edit/${value?._id}`}
                id={value?._id}
                api="delete-product"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: products?.data?.currentPage,
          totalCount: products?.data?.totalCount,
          totalPages: products?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const headerReset = () => {
    let componentData = [];
    headers?.components?.map((value, index) => {
      componentData?.push({
        ...value,
        value: null,
      });
    });
    setHeader({ ...headers, components: componentData });
  };

  useEffect(() => {
    fetchData();
    headerReset();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default ProductsTable;
