import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Settings/Roles/forms";
import headers from "../../../../Data/Forms/Settings/Roles/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Checkbox from "antd/es/checkbox/Checkbox";
import {
  create_role,
  get_role,
  update_role,
} from "../../../../Controllers/Settings/Roles";
import toast from "react-hot-toast";

const RolesForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const [permisions, setPermissions] = useState([
    {
      name: "Dashboard",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Products",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Inventory",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Billing",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Sales",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Purchases",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Expenses",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Customers",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Suppliers",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Reports",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
    {
      name: "Settings",
      full_access: 0,
      view: 0,
      create: 0,
      update: 0,
      delete: 0,
      approve: 0,
    },
  ]);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const role = id
        ? await update_role({
            id: id,
            ...data?.data,
            details: permisions,
          })
        : await create_role({ ...data?.data, details: permisions });

      if (role?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(role?.message);
        setButtonLoader(false);
      } else {
        toast?.error(role?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (role) => {
    let name = role?.name ? role?.name : "";
    let status = role?.status ? role?.status : 0;

    forms[0].options[0].form.value = name;
    forms[1].options[0].form.value = status;

    setPermissions(role?.details);

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      headers.navigate.path[1].name = "Edit";

      const data = {
        id: id,
      };

      const unit = await get_role(data);

      if (unit?.status) {
        handleData(unit?.data);
      } else {
        toast?.error(unit?.message);
      }
    } else {
      setLoader(false);
    }
  };

  const handlePermission = (e, index) => {
    console.log(e?.target?.name == "full_access" && e?.target?.checked);
    console.log(permisions[index].full_access);

    if (e?.target?.name == "full_access" && e?.target?.checked) {
      permisions[index].full_access = Number(true);
      permisions[index].create = Number(true);
      permisions[index].update = Number(true);
      permisions[index].view = Number(true);
      permisions[index].delete = Number(true);
      permisions[index].approve = Number(true);
    } else if (e?.target?.name == "full_access" && !e?.target?.checked) {
      permisions[index].full_access = Number(false);
      permisions[index].create = Number(false);
      permisions[index].update = Number(false);
      permisions[index].view = Number(false);
      permisions[index].delete = Number(false);
      permisions[index].approve = Number(false);
    } else {
      if (!permisions[index].full_access) {
        permisions[index][e?.target?.name] = Number(e?.target?.checked);
      }
    }
    setPermissions([...permisions]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Permissions"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <div>
                <table className="w-full border">
                  <thead className="">
                    <tr className="text-black border">
                      <td className="text-left p-2 border">Name</td>
                      <td className="text-center p-2 border">Full access</td>
                      <td className="text-center p-2 border">View</td>
                      <td className="text-center p-2 border">Create</td>
                      <td className="text-center p-2 border">Update</td>
                      <td className="text-center p-2 border">Delete</td>
                      <td className="text-center p-2 border">Approve</td>
                    </tr>
                  </thead>
                  <tbody>
                    {permisions?.map((value, index) => {
                      return (
                        <tr className="text-gray-500 border">
                          <td className="text-left p-2 border">
                            {value?.name}
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="full_access"
                              checked={Boolean(value?.full_access)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="view"
                              checked={Boolean(value?.view)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="create"
                              checked={Boolean(value?.create)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="update"
                              checked={Boolean(value?.update)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="delete"
                              checked={Boolean(value?.delete)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                          <td className="text-center p-2 border">
                            <Checkbox
                              name="approve"
                              checked={Boolean(value?.approve)}
                              onChange={(e) => handlePermission(e, index)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default RolesForm;
