import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_inventories } from "../../../../Controllers/Inventory/Batch";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Inventory/Batch/tables";
import headers from "../../../../Data/Tables/Inventory/Batch/headers";
import Loadercomponent from "../../../../Componets/Loader";
import ValueTag from "../../../../Componets/Valuetag";
import moment from "moment";

const InventoryBatchTable = () => {
  const [loader, setLoader] = useState(false);
  const [header, setHeader] = useState(headers);
  const [body, setBody] = useState();

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const paginationdata = (data) => {
    let headerdata = {};

    header?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      product: headerdata?.product,
      purchase: headerdata?.purchase,
      supplier: headerdata?.supplier,
      date: headerdata?.date,
      status: headerdata?.status,
    });
  };

  const formdata = (data) => {
    let page = data?.search ? pagination?.currentPage : 1;

    fetchData({
      ...data,
      currentPage: page,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const actiondata = (data) => {
    let headerdata = {};

    headers?.components?.map((value, index) => {
      headerdata[value.name] = value.value;
    });

    fetchData({
      ...data,
      search: headerdata?.search,
      sort: headerdata?.sort,
      product: headerdata?.product,
      purchase: headerdata?.purchase,
      supplier: headerdata?.supplier,
      date: headerdata?.date,
      status: headerdata?.status,
      currentPage: pagination?.currentPage,
      totalCount: pagination?.totalCount,
      totalPages: pagination?.totalPages,
    });
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        product: tabledata?.product,
        purchase: tabledata?.purchase,
        supplier: tabledata?.supplier,
        date: tabledata?.date,
        status: tabledata?.status,
        page: tabledata?.currentPage,
      };

      const products = await get_all_inventories(data);
      if (products?.status) {
        let data = [];

        products?.data?.data?.map((value, index) => {
          data?.push({
            path: `/app/inventory/view/${value?._id}`,
            Number: `#BATCH-${value?.number}`,
            Product: value?.product?.name,
            Purchase: value?.purchase?.number
              ? `#PO-${value?.purchase?.number}`
              : "",
            Supplier: value?.supplier?.name ? value?.supplier?.name : "",
            Sale: parseFloat(value?.sale_price)?.toFixed(3),
            Stock: (
              <ValueTag
                type={value?.product?.type}
                inventory={
                  Number.isInteger(value?.stock)
                    ? parseFloat?.(value?.stock || 0)
                    : parseFloat?.(value?.stock || 0)?.toFixed(3)
                }
              />
            ),
            Tax: value?.tax ? value?.tax + "%" : "0%",
            Expiry: value?.expiry_date
              ? moment?.(value?.expiry_date)?.format("MMMM Do YYYY")
              : "",
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/inventory/edit/${value?._id}`}
                id={value?._id}
                api="delete-inventory"
                actiondata={actiondata}
              />
            ),
          });
        });

        setPagination({
          currentPage: products?.data?.currentPage,
          totalCount: products?.data?.totalCount,
          totalPages: products?.data?.totalPages,
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const headerReset = () => {
    let componentData = [];
    headers?.components?.map((value, index) => {
      componentData?.push({
        ...value,
        value: null,
      });
    });
    setHeader({ ...headers, components: componentData });
  };

  useEffect(() => {
    fetchData();
    headerReset();
  }, []);

  return (
    <Header headers={header} setHeaders={setHeader} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table
          head={tables}
          body={body}
          pagination={pagination}
          paginationdata={paginationdata}
        />
      </Loadercomponent>
    </Header>
  );
};

export default InventoryBatchTable;
