import React, { useEffect, useState } from "react";
import moment from "moment";
import URL from "../../../../Main/url";

const A4Invoice = React.forwardRef((props, ref) => {
  const { values } = props;

  const injectGlobalStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
        @page {
          size: A4;
          margin: 6% 6% 16% 6%;
           @bottom-left {
          font-size: 12px;
        //  content: "\\A \\A \\A \\A Customer's Signature (Name/Designation/Co. Seal) \\A \\A \\A";
          white-space: pre;
          position: fixed;
          bottom: 10mm;
          left: 6%;
        }
        @bottom-right {
          font-size: 12px;
            // content: "\\A \\A \\A \\A For ${"companyName"} \\A \\A " counter(page) " of " counter(pages);
          white-space: pre;
          position: fixed;
          bottom: 10mm;
          right: 6%;
        }
        }
      `;
    document.head.appendChild(style);
  };

  useEffect(() => {
    injectGlobalStyles();
  }, []);

  return (
    <div className="flex justify-center">
      <div className="page-a4" ref={ref}>
        {/*letter header */}
        <div className="text-black-900 text-[0.65rem] text-center">
          <div className="text-center">
            {values?.branch?.image ? (
              <div className="flex justify-center">
                <div className="text-xl pb-2">
                  <img
                    className="w-[50px] h-[50px]"
                    src={`${URL}/uploads${values?.branch?.image}`}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="text-medium text-xl">{values?.branch?.name}</div>
            <div className="text-medium">{values?.branch?.category}</div>
          </div>
        </div>
        <div className="text-black-900 text-[0.65rem] text-center pt-4">
          {/* <span>C.R.&nbsp;:&nbsp;1233305,&nbsp;</span>
                    <span>P.O&nbsp;Box&nbsp;:&nbsp;5678,&nbsp;</span>
                    <span>P.O&nbsp;Code&nbsp;:&nbsp;342,&nbsp;</span>
                    <span>Fax&nbsp;:&nbsp;2132435,&nbsp;</span>
                    <span>Tel&nbsp;:&nbsp;22876756,&nbsp;</span> */}
          <span>
            Email&nbsp;:&nbsp;
            {values?.branch?.email ? values?.branch?.email + ", " : ""}
            &nbsp;
          </span>
          <span>
            Phone&nbsp;:&nbsp;
            {values?.branch?.phone ? values?.branch?.phone : ""}
            &nbsp;
          </span>
          {/* <span>F.Code&nbsp;:&nbsp;8756432,&nbsp;</span> */}
          <div>
            {values?.branch?.area ? values?.branch?.area + ", " : ""}
            {values?.branch?.city ? values?.branch?.city + ", " : ""}
            {values?.branch?.country ? values?.branch?.country : ""}
          </div>
          <div>
            VATIN&nbsp;:&nbsp;
            {values?.branch?.tax ? values?.branch?.tax : "N/A"}
          </div>
        </div>
        {/* heading */}
        <hr className="my-4 border-t-black-900 border-black" />
        <div className="text-medium text-xl text-center">Tax Invoice</div>
        <div className="text-xs pb-6">
          <div className="flex justify-between">
            <div>
              <table>
                <tbody>
                  <tr>
                    <td className="">
                      {values?.customer?.name
                        ? values?.customer?.name
                        : "Walk-in-customer"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      PHONE&nbsp;:&nbsp;{values?.customer?.phone}&nbsp;
                      {values?.supplier?.email
                        ? ", EMAIL : " + values?.customer?.email
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {values?.customer?.city
                        ? values?.customer?.city + ", "
                        : " "}
                      {values?.customer?.state}
                    </td>
                  </tr>
                  <tr>
                    <td>{values?.customer?.country}</td>
                  </tr>
                  <tr>
                    <td>
                      {values?.supplier?.tax
                        ? "VATIN : " + values?.supplier?.tax
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Invoice No</td>
                    <td className="px-2">:</td>
                    <td className="text-right">#INV-{values?.number}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td className="px-2">:</td>
                    <td className="text-right">
                      {moment?.(values?.date)?.format?.("DD-MM-YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>Due</td>
                    <td className="px-2">:</td>
                    <td className="text-right">
                      {moment?.(values?.due_date)?.format?.("DD-MM-YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>Reference</td>
                    <td className="px-2">:</td>
                    <td className="text-right">N/A</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td className="px-2">:</td>
                    <td className="text-right">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table */}
        <div className="">
          <table className="w-full">
            <thead className="bg-black">
              <tr className="text-sm">
                <th className="font-normal  text-white  text-left py-2 pl-3">
                  S.No
                </th>
                <th className="font-normal text-white text-left p-2">
                  Description
                </th>
                <th className="font-normal text-white text-center p-2">
                  Units
                </th>
                <th className="font-normal text-white text-center p-2">
                  Price
                </th>
                <th className="font-normal text-white text-center p-2">
                  Quantity
                </th>
                <th className="font-normal text-white text-center p-2">Tax</th>
                <th className="font-normal text-white text-center p-2">
                  Taxamount
                </th>
                <th className="font-normal text-white text-right pr-2">
                  Total
                </th>
              </tr>
            </thead>
            {/* table body */}
            <tbody className="text-sm">
              {values?.details?.map((value, index) => {
                return (
                  <tr className="uppercase border-b border-gray-300 ">
                    <td className="p-1 text-center">{index + 1}</td>
                    <td className="p-1 text-left">
                      <div className="text-xs text-medium">{value?.name}</div>
                      <div className="text-gray-500 text-xs">
                        #BATCH-{value?.description?.number}
                      </div>
                      <div className="text-gray-500 text-xs">
                        {value?.expiry_date
                          ? `Expiry : ${moment?.(value?.expiry_date)?.format(
                              "DD-MM-YYYY"
                            )}`
                          : ""}
                      </div>
                    </td>
                    <td className="p-1 text-center">{value?.unit_name}</td>
                    <td className="p-1 text-center">
                      {value?.sale_price?.toFixed(3)}
                    </td>
                    <td className="p-1 text-center">
                      {value?.quantity?.toFixed(3)}
                    </td>
                    <td className="p-1 text-center">{value?.tax}%</td>
                    <td className="p-1 text-center">
                      {value?.tax_amount?.toFixed(3)}
                    </td>
                    <td className="text-right p-2">
                      {value?.total?.toFixed(3)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* table foot */}
            <tfoot className="text-sm ">
              <tr className="">
                <th colSpan={6}></th>
                <th className="font-normal pt-1 text-right p-1">Total</th>
                <td className="font-normal text-right p-1">
                  {values?.subtotal?.toFixed(3)}
                </td>
              </tr>
              <tr>
                <th colSpan={6}></th>
                <th className="font-normal text-right p-1">VAT (5%)</th>
                <td className="font-normal text-right p-1">
                  {values?.taxamount?.toFixed(3)}
                </td>
              </tr>
              <tr>
                <th colSpan={6}></th>
                <th className="font-normal text-right p-1">Discount</th>
                <td className="font-normal text-right p-1">
                  {values?.discount
                    ? "-" + values?.discount?.toFixed(3)
                    : "0.000"}
                </td>
              </tr>
              <tr>
                <th colSpan={6}></th>
                <th className="font-normal text-right p-1">Delivery</th>
                <td className="font-normal text-right p-1">
                  {values?.delivery
                    ? "+" + values?.delivery?.toFixed(3)
                    : "0.000"}
                </td>
              </tr>
              <tr>
                <th className="" colSpan={6}></th>
                <th className="font-normal text-right text-white bg-black p-2">
                  Grand&nbsp;Total&nbsp;(OMR)
                </th>
                <td className="font-normal text-right text-white bg-black p-2">
                  {values?.total?.toFixed(3)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* note */}
        <div className="pt-4 text-sm">
          <div className="">
            {/* <div className="font-semibold">Note&nbsp;&nbsp;</div>
                      <li>
                        Please feel free to contact us for any questions or concerns.
                      </li> */}
          </div>
          <div className="pt-4">
            {/* <div className="font-semibold">
                        Terms & Conditions&nbsp;&nbsp;
                      </div>
                      <li>Quote validity for 30 days.</li> */}
          </div>
        </div>
        {/* <div className="">
                      <div
                        className="page-footer mb-[1.5cm]"
                        style={{ padding: " 1.6cm" }}
                      >
                        <div className="flex justify-between px-[0.5cm]">
                          <div className="text-[0.65rem]">
                            Customer's Signature (Name/Designation/Co. Seal)
                          </div>
                          <div className="text-[0.65rem]">For New Pharmacy LLC</div>
                        </div>
                      </div>
                    </div> */}
      </div>
    </div>
  );
});

export default A4Invoice;
