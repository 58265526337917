import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import BarcodeIcon from "../../Assets/Svg/barcode";
import { useState } from "react";

const BarcodeButton = (props) => {
  const { isFocused, setIsFocused } = props;

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <button
      type="button"
      className="border-0 p-0"
      size="large"
      onFocus={handleFocus}
      style={{ outline: "none" }}
      onBlur={handleBlur}
      {...props}
    >
      <div className={isFocused ? "text-[#07B553]" : "text-red-500"}>
        <BarcodeIcon width={35} height={35} />
      </div>
    </button>
  );
};

export default BarcodeButton;
