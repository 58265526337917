const headers = {
  name: "Products",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      allowClear: true,
      showSearch: false,
      filterOption: true,
      value: null,
      options: [
        { value: 0, label: "Name (A-Z)" },
        { value: 1, label: "Name (Z-A)" },
        { value: 2, label: "Stock (Low)" },
        { value: 3, label: "Stock (High)" },
      ],
    },
    {
      type: "filter",
      name: "unit",
      allowClear: true,
      showSearch: true,
      filterOption: false,
      placeholder: "Units",
      api: "get-all-product-units",
      value: null,
      options: null,
    },
    {
      type: "filter",
      name: "category",
      placeholder: "Category",
      allowClear: true,
      showSearch: true,
      filterOption: false,
      api: "get-all-product-categories",
      value: null,
      options: null,
    },
    {
      type: "filter",
      name: "brand",
      placeholder: "Brands",
      allowClear: true,
      showSearch: true,
      filterOption: false,
      api: "get-all-product-brands",
      value: null,
      options: null,
    },
    {
      type: "filter",
      name: "type",
      placeholder: "Type",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        { value: 0, label: "Non-Stockable" },
        { value: 1, label: "Stockable" },
      ],
    },
    {
      type: "filter",
      name: "status",
      placeholder: "Status",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },
    {
      type: "add",
      path: "/app/products/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Products",
        path: "",
      },
      {
        name: "Units",
        path: "/app/products/units",
      },
      {
        name: "Categories",
        path: "/app/products/categories",
      },
      {
        name: "Brands",
        path: "/app/products/brands",
      },
    ],
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
      { value: 0, label: "Stockable" },
      { value: 0, label: "Non-Stockable" },
    ],
  },
};

export default headers;
