import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_expense = async (expense) => {
  try {
    let data = {
      number: expense?.number,
      date: expense?.date,
      category: expense?.category,
      description: expense?.description,
      amount: expense?.amount,
      status: expense?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/create-expense`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const update_expense = async (expense) => {
  try {
    let data = {
      id: expense?.id,
      number: expense?.number,
      date: expense?.date,
      category: expense?.category,
      description: expense?.description,
      amount: expense?.amount,
      status: expense?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/update-expense`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_expense = async (expense) => {
  try {
    let data = {
      id: expense?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(`${APIURL}/get-expense`, data, config);

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_expenses = async (expense) => {
  try {
    const data = {
      search: expense?.search,
      category: expense?.category,
      status: expense?.status,
      sort: expense?.sort,
      date: expense?.date,
      page: expense?.page,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-expenses`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_expense_log = async (expense) => {
  try {
    const data = {
      id: expense?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-expense-log`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

const get_all_expense_log = async (expense) => {
  try {
    const data = {
      expense: expense?.expense,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-expense-log`,
      data,
      config
    );

    if (response?.data?.status_code == 401) {
      localStorage.clear();
      window.location.href = "/signin";
      return response?.data;
    } else {
      return response?.data;
    }
  } catch (error) {
    return error;
  }
};

export {
  create_expense,
  update_expense,
  get_expense,
  get_all_expenses,
  get_expense_log,
  get_all_expense_log,
};
