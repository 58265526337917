import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Signin } from "../../../Controllers/Auth";
import toast from "react-hot-toast";
import Authorization from "../../../Main/authorization";
import Form from "../../../Componets/Form";
import forms from "../../../Data/Forms/Signin/forms";
import { Button } from "antd";

const SigninForm = () => {
  let navigate = useNavigate();

  let isAuthenticate = Authorization();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      const signup = await Signin(data?.data);

      if (signup?.status) {
        navigate("/app/dashboard");
        toast?.success(signup?.message);
      } else {
        toast?.error(signup?.message);
      }
    }
  };

  const fetchData = async () => {
    values[0].button = (
      <div>
        <Button
          size="large"
          className="bg-[#07b553] text-medium form-add-button w-full"
          type="primary"
          htmlType="submit"
        >
          Signin
        </Button>
        <div className="pt-3 text-medium text-gray-500 text-center">
          <span>Don't have an account?</span>
          &nbsp;
          <button type="button" onClick={() => navigate("/signup")}>
            <u className="text-[#07b553]">Signup</u>
          </button>
        </div>
      </div>
    );

    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bg-gray-100">
      {isAuthenticate && <Navigate to="/app/dashboard" />}
      <Form form={values} setForm={setValues} formdata={formdata} />
    </div>
  );
};

export default SigninForm;
