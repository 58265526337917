const tables = [
  {
    name: "Name",
  },
  {
    name: "Phone",
  },
  {
    name: "Email",
  },
  {
    name: "Country",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];

export default tables;
