import React from "react";

const Bodycomponent = (props) => {
  const { form } = props;

  return (
    <div
      className={form?.[0]?.body?.className ? form?.[0]?.body?.className : ""}
    >
      {props?.children}
    </div>
  );
};

export default Bodycomponent;
