import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode";
import products from "../../../../Data/products.json";
import { useParams } from "react-router-dom";
import { PrinterOutlined } from "@ant-design/icons";
import Loadercomponent from "../../../../Componets/Loader";
import { get_inventory } from "../../../../Controllers/Inventory/Batch";
import toast from "react-hot-toast";

const InventoryView = () => {
  const { id } = useParams();

  const barcodeRef = useRef();

  const handleBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState([]);

  const handleData = async (inventory) => {
    setLoader(false);
    setValues(inventory);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const inventory = await get_inventory(data);
      if (inventory?.status) {
        handleData(inventory?.data);
      } else {
        toast?.error(inventory?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Loadercomponent loader={loader}>
        {values?.barcode ? (
          <div className="p-4">
            <button className="pb-4" onClick={handleBarcode}>
              Print Barcode <PrinterOutlined />
            </button>
            <Barcode ref={barcodeRef} value={values?.barcode} />
          </div>
        ) : (
          <div className="p-4 text-red-500">Barcode not available</div>
        )}
      </Loadercomponent>
    </div>
  );
};

export default InventoryView;
