import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Inventory/Transfer/forms";
import headers from "../../../../Data/Forms/Inventory/Transfer/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
  create_transfer,
  get_transfer,
  update_transfer,
} from "../../../../Controllers/Inventory/Transfer";
import Transfertable from "../../../../Data/Forms/Inventory/Transfer/table";

const InventoryTransferForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const focus = useRef([]);

  const [details, setDetails] = useState([
    {
      id: "",
      description: null,
      name: "",
      unit: "",
      unit_name: "",
      unit_details_options: null,
      conversion: 0,
      quantity: "",
      invoice: "",
      invoice_price: "",
      invoice_options: null,
      delivered: "",
      tax: 0,
      free: "",
      barcode: "",
      price_per_unit: "",
      sale_price: "",
      expiry_date: "",
      tax_amount: "0.000",
      total: 0,
    },
  ]);

  const [totals, setTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    discount: 0,
    delivery: 0,
    delivery_status: 2,
    delivery_date: dayjs(),
    payment_status: 2,
    payment_types: ["Cash"],
    payments: {
      Cash: "",
      "Debit card": "",
      "Credit card": "",
      "Bank transfer": "",
      "Online payment": "",
      Cheque: "",
    },
    paid: 0,
    remaining: 0,
    total: "0.000",
  });

  const detailsvalidation = (tabledata) => {
    let params = {};

    if (details?.length > 0) {
      let total = details?.length;
      let count = 0;

      let validated = [];
      details?.map((v, i) => {
        if (count == i) {
          if (!v?.description) {
            document.getElementById(
              `description${i}`
            ).textContent = `Description is required`;
            focus.current[`description${i}`].current.focus();
          } else if (!v?.purchase_price) {
            document.getElementById(
              `purchase_price${i}`
            ).textContent = `Purchase price is required`;
            focus.current[`purchase_price${i}`].current.focus();
          } else if (!v?.quantity) {
            document.getElementById(
              `quantity${i}`
            ).textContent = `Quantity is required`;
            focus.current[`quantity${i}`].current.focus();
          } else {
            document.getElementById(`description${i}`).textContent = ``;
            document.getElementById(`purchase_price${i}`).textContent = ``;
            document.getElementById(`quantity${i}`).textContent = ``;

            validated?.push(v);
            count++;
          }
        }
      });

      if (count == total) {
        params = {
          status: true,
          data: {
            ...tabledata,
            details: validated,
          },
        };

        return params;
      } else {
        params = {
          status: false,
          data: null,
        };

        return params;
      }
    } else {
      toast?.error("Details not found");
    }
  };

  const totalsvalidation = (tabledata) => {
    let params = {};

    let count_totals = 0;

    if (totals?.delivery_status == 2 && !totals?.delivery_date) {
      document.getElementById(
        `delivery_date`
      ).textContent = `Delivery Date is required`;
      focus.current[`delivery_date`].current.focus();
    } else if (totals?.payment_status && !totals?.payment_types?.length) {
      document.getElementById(
        `payment_types`
      ).textContent = `Payment type is required`;
      focus.current[`payment_types`].current.focus();
    } else {
      if (totals?.delivery_status == 2) {
        document.getElementById(`delivery_date`).textContent = ``;
      }
      if (totals?.payment_status) {
        document.getElementById(`payment_types`).textContent = ``;
      }
      count_totals++;
    }

    //payments  validation
    if (count_totals) {
      let total_payment_types = totals?.payment_types?.length;
      let count_payment_types = 0;

      if (totals?.payment_types?.length > 0) {
        totals?.payment_types?.map((v, i) => {
          if (count_payment_types == i) {
            if (!totals?.payments?.[v]?.amount) {
              document.getElementById(`${v}`).textContent = `${v} is required`;
              focus.current[`${v}`].current.focus();
            } else {
              document.getElementById(`${v}`).textContent = ``;
              count_payment_types++;
            }
          }
        });

        if (count_payment_types == total_payment_types) {
          params = {
            status: true,
            data: {
              ...tabledata,
              id: id ? id : "",
              subtotal: totals?.subtotal,
              taxamount: totals?.taxamount,
              discount: totals?.discount,
              delivery: totals?.delivery,
              delivery_status: totals?.delivery_status,
              delivery_date: totals?.delivery_date,
              payment_status: totals?.payment_status,
              payment_types: totals?.payment_types,
              payments: totals?.payments,
              total: totals?.total,
            },
          };
          return params;
        } else {
          params = {
            status: false,
            data: null,
          };

          return params;
        }
      } else {
        params = {
          status: true,
          data: {
            ...tabledata,
            id: id ? id : "",
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          },
        };

        return params;
      }
    }
  };

  const formdata = async (data) => {
    if (data?.status) {
      const tabledetailsvalidation = detailsvalidation(data?.data);

      if (tabledetailsvalidation?.status) {
        // const tabletotalvalidation = totalsvalidation(
        //   tabledetailsvalidation?.data
        // );

        // if (tabletotalvalidation?.status) {
        setButtonLoader(true);
        const invoice = id
          ? await update_transfer({
              ...tabledetailsvalidation?.data,
              id: id,
            })
          : await create_transfer(tabledetailsvalidation?.data);
        // ? await update_transfer(tabletotalvalidation?.data)
        // : await create_transfer(tabletotalvalidation?.data);
        if (invoice?.status) {
          if (id) {
            navigate(-1);
          }
          toast?.success(invoice?.message);
          setButtonLoader(false);
        } else {
          toast?.error(invoice?.message);
          setButtonLoader(false);
        }
        // }
      }
    }
  };

  const handleData = (invoice) => {
    let supplier = invoice?.supplier?._id ? invoice?.supplier?._id : null;
    let number = invoice?.number ? invoice?.number : "";
    let date = invoice?.date ? invoice?.date : "";
    let due_date = invoice?.due_date ? invoice?.due_date : "";
    let status = invoice?.status ? invoice?.status : 0;

    let supplier_option = invoice?.supplier?._id
      ? [
          {
            value: invoice?.supplier?._id,
            label: invoice?.supplier?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = supplier;
    values[0].options[0].form.options = supplier_option;
    values[0].options[1].form.value = number;
    values[0].options[2].form.value = date;
    values[0].options[3].form.value = due_date;
    values[0].options[4].form.value = status;

    let data = [];

    invoice?.details?.map((value, index) => {
      let stock = value?.description?.stock;

      if (value?.unit != value?.description?._id) {
        value?.inventory_unit_details?.map((v, i) => {
          if (value?.unit == v?._id) {
            stock = v?.stock;
          }
        });
      }

      data?.push({
        id: value?._id,
        description: value?.description?._id,
        batch: value?.description?.number,
        expiry_date: value?.description?.expiry_date,
        stock: stock,
        name: value?.name,
        unit: value?.unit,
        unit_name: value?.unit_name,
        unit_details_options: value?.unit_details_options,
        conversion: value?.conversion,
        invoice_price: value?.invoice_price,
        quantity: value?.quantity,
        delivered: value?.delivered,
        free: value?.free,
        tax: value?.tax,
        barcode: value?.barcode,
        purchase_price: value?.purchase_price,
        price_per_unit: value?.price_per_unit,
        sale_price: value?.sale_price,
        expiry_date: value?.expiry_date,
        tax_amount: value?.tax_amount,
        total: value?.total,
      });
    });

    let payment_types = [];
    let payments = {};
    invoice?.payments?.map((v, i) => {
      payment_types?.push(v?.name);
      payments[v?.name] = { id: v?._id, amount: v?.amount };
    });

    totals.subtotal = invoice?.subtotal;
    totals.taxamount = invoice?.taxamount;
    totals.discount = invoice?.discount;
    totals.delivery = invoice?.delivery;
    totals.delivery_status = invoice?.delivery_status;
    totals.delivery_date = invoice?.delivery_date;
    totals.payment_status = invoice?.payment_status;
    totals.payment_types = payment_types;
    totals.payments = payments;
    totals.total = invoice?.total;

    setValues([...values]);
    setDetails([...data]);
    setTotals({ ...totals });
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const invoice = await get_transfer(data);
      if (invoice?.status) {
        handleData(invoice?.data);
      } else {
        toast?.error(invoice?.message);
      }
    } else {
      values[0].options[2].form.value = new Date();
      values[0].options[3].form.value = new Date();

      totals.delivery_status = 2;
      totals.delivery_date = new Date();
      totals.payment_status = 2;
      totals.payment_types = ["Cash"];

      setValues([...values]);
      setTotals({ ...totals });
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Items"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={true} />
              <Transfertable
                details={details}
                setDetails={setDetails}
                totals={totals}
                setTotals={setTotals}
                ref={focus}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default InventoryTransferForm;
